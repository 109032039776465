<template>
  <ion-slide class="a-slide">
    <div class="slide-inside" v-if="featureHeader">
      <div :class="['slide-header', {'slide-header-long': headerLong}]">
        <div>
          <img class="foodable-heart" v-if="headerHeart" src="../../assets/img/foodable-icon.svg" alt="">
          <div class="headline" v-if="title">{{ title }}</div>
          <slot name="header"></slot>
        </div>
      </div>
      <div class="slide-content">
        <slot></slot>  
      </div>
      <slot name="root"></slot>
    </div>
    <Page :title="title" v-if="!featureHeader" :noPaddingTop="noPaddingTop || didyouorder" :hideback="true" :shoppingList="shoppingList">
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
      <div class="progress-bar" v-if="progress">
        <div class="progress" :style="'width: ' + progress + '%'"></div>
      </div>
      <div :class="['didyouorder', { 'didyouorder-with-shopping-list' : shoppingList }]" v-if="didyouorder" @click="$store.dispatch('openModal', {name: 'didyouorder'})">
        <InfoBox icon="foodableHeart">Hast du diese Kochbox schon bei REWE bestellt? <div class="btn-link">Jetzt zum Bestätigen</div></InfoBox>
      </div>
      <slot></slot>
    </Page>
  </ion-slide>
</template>

<script>
import { 
  IonSlide
} from '@ionic/vue'

import Page from '@/components/Page.vue'
import InfoBox from '@/components/info/InfoBox.vue'

export default {
  name: 'valueslides',
  props: ['title', 'headerHeart', 'headerLong', 'featureHeader', 'progress', 'noPaddingTop', 'shoppingList', 'didyouorder'],
  components: {
    IonSlide,
    Page,
    InfoBox
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.a-slide {
  height: 100%;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}

.slide-inside {
  padding-bottom: 150px;
}

.slide-content {
  width: 100%;
  height: 100%;
}

.slide-header {
  width: 100vw;
  min-height: 140px;
  background: $col-pastell-green;
  padding-top: #{calc(constant(safe-area-inset-top) + 20px)};
  padding-top: #{calc(env(safe-area-inset-top) + 20px)};
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-header-long {
  padding-bottom: 120px;
  margin-bottom: -100px;
}

.foodable-heart {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.progress-bar {
  background: $col-lighter-gray-font;
  border-radius: 50px;
  height: 9px;
  margin: 20px;
}

.progress {
  background: $col-main-green;
  border-radius: 50px;
  height: 100%;
} 

.didyouorder {
  margin-bottom: 20px;
}

.didyouorder-with-shopping-list {
  margin-top: -40px;
}
</style>