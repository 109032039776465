<!--

      This component is the foundation for several Slides views, such as
      the createplan wizard or the onboarding wizard.

 -->

<template>
  <Page
    class="slides-page"
    :hideback="true"
    :scroll="false"
    :hideheader="true"
    :hidelargeheader="true"
    :noPaddingTop="true"
    :key="updateSlides"
    ref="slidespage"
  >
    <div class="back-button-area">
      <Icon name="foodableArrowBack" v-show="this.currentslide === 0 && this.showBack" @click="navigateBack()" />
      <Icon name="foodableArrowBack" v-show="currentslide > 0" @click="prev()" />
    </div>
    <ion-slides ref="slider" class="slides" :options="slideOpts" @ionSlideDidChange="swipingHappened($event)">
      <slot></slot>
    </ion-slides>
    <div class="next-buttons">
      <div
        :class="ctaButtonClasses"
        @click="forward()"
        data-cy="submit"
        v-show="!this.hideNextButton"
        :disabled="this.showLoadingButton"
      >
        <div class="button-inside" :key="this.currentslide">
          <span v-if="!this.showLoadingButton">
            {{ showCTA }}
          </span>
          <ion-spinner v-show="this.showLoadingButton" name="dots" class="spinner-cta"></ion-spinner>
          <span v-if="this.currentslide <= 5 && !this.showLoadingButton && this.$store.state.neworder && this.analyticsEvent === 'menu_wizard' && showCTA !== 'Kostenpflichtig bestellen'">
            <span class="whitespace"></span>·<span class="whitespace"></span><Price :key="this.$store.state.neworder.price" :price="this.$store.state.neworder.price" :estimated="externalPayment" />
          </span>
        </div>
      </div>
      <div :class="['btn-tertiary-small text-center', 'skip-button', {'show-skip': showSkip}]" @click="skip()">Überspringen</div>
      <div class="bullets">
        <div v-show="progress === 'bullets'" v-for="s in slideCount" :key="s" :class="['bullet', {'bullet-active': s-1 === currentslide}]"></div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Price from '@/components/Price.vue'
import {
  IonSlides,
  IonSpinner,
} from '@ionic/vue'
import { Plugins, HapticsImpactStyle } from '@capacitor/core'
const { App, Keyboard, Haptics } = Plugins
import { useBackButton } from '@ionic/vue'


export default {
  name: 'slides',
  props: {
    showBack: Boolean,
    hideNextButtonOnSlides: {
      type: Array,
      default: []
    },
    cta : {
      type: Array,
      default: Array(10).fill('Weiter')
    },
    showLoadingButton: {
      type: Boolean,
      default: false
    },
    redirect: Function,
    analyticsEvent: String,
    navigateBack: Function,
    trackUniqueEvents: Boolean,
    showSkip: Boolean,
    progress: String,
    nameOfRoute: String
  },
  emits: ['clicked'],
  components: {
    Page,
    Price,
    IonSlides,
    IonSpinner,
  },
  setup() {
    const slideOpts = {
      slidesPerView: 1,
      speed: 600,
      observer: true,
      observeParents: true,
      parallax: true,
      touchMoveStopPropagation: true,
      touchStartPreventDefault: false
    }
    return { slideOpts }
  },
  data() {
    return {
      currentslide: null,
      keyboardIsActive: null,
      slidesAreVisible: null,
      slideCount: null,
      updateSlides: 0
    }
  },
  computed: {
    hideNextButton () {
      this.togglePager()
      if (this.hideNextButtonOnSlides.indexOf(this.currentslide) > -1 || this.keyboardIsActive) {
        return true
      }
    },
    ctaButtonClasses () {
      if (this.showLoadingButton) {
        return 'btn-disabled btn-primary standalone'
      } else {
        return 'btn-primary standalone'
      }
    },
    showCTA () {
      if (this.cta && this.cta.length > 0) {
        return this.cta[this.currentslide]
      }
    },
    externalPayment () {
      return this.$store.state.profile && !this.$store.state.profile.preferred_store_branch.use_internal_payment
    },
  },
  watch : {
    currentslide () {
      // determine if swiping is allowed on this currentslide
      if (this.currentslide === 2 && this.analyticsEvent === 'menu_wizard' && this.$store.getters.oldCustomerJourney) {
        this.$refs.slider.$el.lockSwipes(true)
      } else {
        this.$refs.slider.$el.lockSwipes(false)
      }

      return this.currentslide
    }
  },
  methods: {
    countTheSlides () {
      if (this.$refs.slider) {
        this.slideCount = this.$refs.slider.$el.childElementCount
      }
    },
    swipingHappened (event) {
      const oldCurrentSlide = this.currentslide
      this.currentslide = event.target.swiper.activeIndex
      const previousSlide = event.target.swiper.previousIndex

      // This function 'swipingHappened' will run in two cases:
      // 1. Either the user swiped forward.
      // 2. Or the user clicked on the next button to go forward.
      //    In this case, the currentslide will stay the same!
      //    Therefore, we can distinguish the cases.
      //
      // Only in the first case we want to emit 'clicked' and 
      // log Analytics events, because in the other case 2, it
      // already happened in the function "forward".
      // Furthermore, Analytics events shall not be tracked
      // when navigating backwards.
      const userClickedNextButton = oldCurrentSlide === this.currentslide
      const navigatingForwards = this.currentslide > previousSlide
      if (!userClickedNextButton && navigatingForwards) {
        if (this.analyticsEvent) {
          this.$store.dispatch('logAnalyticsEvent', { name: this.analyticsEvent + '_weiter_' + (this.currentslide + 1) })
          if (this.trackUniqueEvents) {
            this.$store.dispatch('logAnalyticsEvent', { name: this.analyticsEvent + '_weiter_' + (this.currentslide + 1) + '_unique' })
          }
        }
        // Now, we need to be careful. Since the function
        // "forward" always reports the old currentslide of the
        // previous slide, we also need to do this here, in
        // order to stay consistent.
        this.$emit('clicked', previousSlide)
      }
    },
    togglePager () {
	    const pager = document.getElementsByClassName('swiper-pagination')[0]
      if (pager) {
        if (this.keyboardIsActive) {
          pager.style.display = 'none'
        } else {
          pager.style.display = 'initial'
        }
      }
    },
    forward () {
      Haptics.impact({style: HapticsImpactStyle.Medium})

      if (!this.showLoadingButton) {

        // Special case: do not allow user to go to supermarket if shopping cart is empty!
        if (this.analyticsEvent === 'menu_wizard' && this.$store.getters.oldCustomerJourney && this.currentslide === 5 && !this.$store.state.neworder.price) {
          this.$store.dispatch('showNotification', {
            message: 'Deine Einkaufsliste ist immer noch leer! Bitte gehe zurück und füge Produkte hinzu...',
            type: 'Error'
          })
        } else {
          this.updateCurrentSlideNumber()
          this.$emit('clicked', this.currentslide)
          this.$refs.slider.$el.lockSwipes(false)
          this.$refs.slider.$el.slideNext()

          if (this.analyticsEvent) {
            this.$store.dispatch('logAnalyticsEvent', { name: this.analyticsEvent + '_weiter_' + (this.currentslide + 1) })
            if (this.trackUniqueEvents) {
              this.$store.dispatch('logAnalyticsEvent', { name: this.analyticsEvent + '_weiter_' + (this.currentslide + 1) + '_unique' })
            }
          }

          if (this.currentslide < this.slideCount - 1) {
            this.currentslide += 1
          } else if (this.currentslide === this.slideCount - 1) {
            this.redirect() // We reached the end of the slides -> run redirect fnc
          }
          this.updateCurrentSlideNumber()
        }
      }
    },
    skip () {
      this.$store.dispatch('logAnalyticsEvent', { name: this.analyticsEvent + '_skipped_at_slide_' + (this.currentslide + 1) })
      this.redirect()
    },
    prev () {
      Haptics.impact({style: HapticsImpactStyle.Medium})
      this.updateCurrentSlideNumber()
      this.$refs.slider.$el.lockSwipes(false)
      this.$refs.slider.$el.slidePrev()
      if (this.currentslide > 0) {
        this.currentslide -= 1
      } else if (this.$store.state.onboarded || this.analyticsEvent != 'onboarding') {
        this.$router.go(-1)
      }
      this.updateCurrentSlideNumber()
    },
    updateCurrentSlideNumber() {
      this.$refs.slider.$el.getActiveIndex().then(val => {
        this.currentslide = val
      })
    },
  },
  created () {
    if (process.env.NODE_ENV !== 'development') {
      Keyboard.addListener('keyboardWillShow', () => {
        this.keyboardIsActive = true
      })
      Keyboard.addListener('keyboardDidHide', () => {
        this.keyboardIsActive = null
      })
    }
  },
  mounted() {
    this.countTheSlides()
    this.currentslide = 0
    useBackButton(10, (processNextHandler) => {
      if (this.$router.currentRoute._value.name === this.nameOfRoute) {
        this.prev()
      } else {
        processNextHandler()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.slides-page {
  height: 100%;
}

.slides {
  height: 100vh;
  width: 100vw;
}

.bullets {
  display: flex;
  justify-content: center;
  margin: 0 20px 20px 20px;
  min-height: 10px;
}

.bullet {
  background: $col-lighter-gray-font;
  border-radius: 100px;
  width: 14px;
  height: 14px;
  margin: 7px;
}

.bullet-active {
  background: $col-light-gray-font;
}

.spinner-cta {
  --color: white;
  display: block;
  height: 20px;
  margin: 0 auto;
}

.skip-button {
  margin-top: -2px;
  margin-bottom: 10px;
  display: none;
}

.show-skip {
  display: block;
}

.whitespace {
  display: inline-block;
  margin: 0 3px !important;
}
</style>
